import React, { useEffect, useState,useRef } from 'react';
import { Flex,Button, InputGroup, Spacer, Table, Tbody, Text, Td, Th, Thead, Tr, useColorModeValue, InputLeftElement, IconButton, Input, Icon, Link, useDisclosure } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';

import { useToast } from '@chakra-ui/react';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import axios from 'axios';
import { server_url } from "constants/types";
import { useHistory } from 'react-router-dom';
import { AddIcon } from '@chakra-ui/icons';
import { DELIVARYINOUT_CLEAR_SNACKBAR , DELIVARYINOUT_SEARCH  } from 'constants/types';

import CurrentTableRow from './CurrentTableRow';
import { DelivaryInOutGetAll } from '../../../actions/delivaryInOutAction';
import CurrentPagination from './CurrentPagination';
import CurrentAdvanceSearch from './CurrentAdvanceSearch';
import { Skeleton, SkeletonCircle, SkeletonText,Stack } from '@chakra-ui/react'
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import DeliveryInOutExport from '../DeliveryInOutExport';

const CurrentTable = () => {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const responseStatus = useSelector(state => state.delivaryInOut.status);
  const responseMessage = useSelector(state => state.delivaryInOut.text);
  const loading = useSelector(state => state.delivaryInOut.loading);
  const DelivaryInOutList = useSelector(state => state.delivaryInOut.delivaryInOutList);
  const response = useSelector(state => state.delivaryInOut.response);
  const showSnackbar = useSelector(state => state.delivaryInOut.showSnackbar);
  const currentPage = useSelector(state => state.delivaryInOut.page);
  const totalItems = useSelector(state => state.delivaryInOut.totalItems);
  const limit = useSelector(state => state.delivaryInOut.limit);
  const dispatch = useDispatch();
  const toast = useToast();
  const searchIconColor = useColorModeValue("gray.700", "gray.200");
  const inputBg = useColorModeValue("#edf3f8", "navy.800");
  const [search, setSearch] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const history = useHistory();
  const tableRef = useRef(null);
  useEffect(() => {
    dispatch(DelivaryInOutGetAll("&type=IN"));
    dispatch({
      type : DELIVARYINOUT_SEARCH,
      payload : {
        searching : ""
      }
    })
  }, [dispatch]);
  console.log("DelivaryInOutList")
  console.log(DelivaryInOutList)

  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && !loading) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
        }
      } else if (responseStatus === 'NOK' && !loading) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }
    dispatch({
      type: DELIVARYINOUT_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("desc");
    }
    const sorting = "&sortOrder=" + sortOrder + "&column=" + column;
    dispatch({
      type : DELIVARYINOUT_SEARCH,
      payload : {
        searching : ""
      }
    })
    dispatch(DelivaryInOutGetAll(sorting+"&type=IN"));
  };

  useEffect(() => {
    const searching = "&search=" + search;
    dispatch({
      type : DELIVARYINOUT_SEARCH,
      payload : {
        searching : ""
      }
    })
    dispatch(DelivaryInOutGetAll(searching+"&type=IN"));
  }, [search]);
  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, []);

  const [submittingLoader, setSubmittingLoader] = useState(false);
  const exportToExcel = async () => {
    setSubmittingLoader(true);
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet('Sheet 1');
    const filename = "Current_Delivery_"+Date.now();
    // Define the headers
    const headers = [
      "SNo.","Date", "Delivery From", "Dept.", "Sub Dept","Parcel","Status","Handed Over","Pending","In Time","Out Time","Total Time","Last Status"
    ];

    // Set up the columns with specific widths
    sheet.columns = [
      { header: 'SNo', key: 'slNo', width: 15 },
      { header: 'Date', key: 'date', width: 20 },
      { header: 'Delivery From', key: 'deliveryFrom', width: 20 },
      { header: 'Dept.', key: 'department', width: 20 },
      { header: 'Sub Department', key: 'subDepartment', width: 20 },
      { header: 'Parcel', key: 'parcel', width: 20 },
      { header: 'Status', key: 'status', width: 20 },
      { header: 'Handed Over', key: 'handedOver', width: 20 },
      { header: 'Pending', key: 'pending', width: 20 },
      { header: 'In Time', key: 'inTime', width: 20 },
      { header: 'Out Time', key: 'outTime', width: 20 },
      { header: 'Total Time', key: 'totalTime', width: 20 },
      { header: 'Last Status', key: 'lastStatus', width: 20 },

    ];

    // Add the headers to the sheet
    headers.forEach((header, index) => {
      sheet.getCell(1, index + 1).value = header;
    });

    // Add data and images
    for (const [rowIndex, row] of DelivaryInOutList.entries()) {
      const rowData = [
        totalItems - (((currentPage - 1) * limit) + rowIndex),
        row.date,
        row.deliveryFrom?.name,
        row.departmentId?.name,
        row.subDepartmentId?.name,
        row.parcel,
        row.status,
        row.handedOver,
        row.pending,
        row.inDateTime,
        row.outDateTime,
        row.totalTime,
        row.lastStatus[0]['description'],
      ];

      for (const [colIndex, cell] of rowData.entries()) {
        
          sheet.getCell(rowIndex + 2, colIndex + 1).value = `${cell}`;
          
      }
    }

    // Set row height (if needed)
    for (let rowIndex = 1; rowIndex <= sheet.rowCount; rowIndex++) {
      sheet.getRow(rowIndex).height = 60; // Set height in points
    }

    // Generate Excel file and save
    const excelBuffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([excelBuffer]), `${filename}.xlsx`);
    setSubmittingLoader(false);
  };
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
<>
{/* <CurrentAdvanceSearch></CurrentAdvanceSearch> */}
<Flex direction="column" pt={{ base: "120px", md: "10px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <Flex>
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
            Deliverys
            </Text>
          </CardHeader>
          <Spacer />
          <Flex justify="flex-end" style={{ padding: '10px', marginTop: '-5px', marginRight: '12px' }}>
            {/* <DownloadTableExcel
              filename="Current Delivary Data"
              sheet="users"
              currentTableRef={tableRef.current}
            > */}
              <Button
              loading={submittingLoader}
              onClick={onOpen}
               colorScheme="blue" _hover={{ backgroundColor: 'black', color: 'white' }}>
                Download
              </Button>
            {/* </DownloadTableExcel> */}
          </Flex>
          {/* Export Modal */}
          <DeliveryInOutExport
            reportName = "Past Delivery Data"
            isOpen={isOpen}
            onClose={onClose}
            onExport={exportToExcel}
            />
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              <InputGroup borderRadius="8px" w="200px">
                <InputLeftElement
                  children={
                    <IconButton
                      bg="inherit"
                      borderRadius="inherit"
                      _hover="none"
                      _active={{
                        bg: "inherit",
                        transform: "none",
                        borderColor: "transparent",
                      }}
                      _focus={{
                        boxShadow: "none",
                      }}
                      icon={
                        <SearchIcon
                          color={searchIconColor}
                          w="15px"
                          h="15px"
                        />
                      }
                    ></IconButton>
                  }
                />
                <Input
                  variant="search"
                  fontSize="xs"
                  bg={inputBg}
                  onKeyUp={(e) => {
                    setSearch(e.target.value);
                    if (e.target.value.length < 3) {
                      setSearch("");
                    }
                  }}
                  placeholder="Type here..."
                />
              </InputGroup>
            </Text>
          </CardHeader>
        </Flex>
        <CardBody pb={4} >
        <div style={{ overflowX: "auto",maxHeight:"500px" }}>
          {loading ? <Stack>
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                  </Stack>:<Table variant="simple" color={textColor} ref={tableRef}>
            <Thead className='stick-header'>
              <Tr my=".8rem" pl="0px" color="gray.400">
              <Th
                    pl="15px"
                    borderColor={borderColor}
                    color="gray.400"
                    cursor="pointer"
                  >
                   SNO
                  </Th>
                <Th
                  pl="0px"
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("vechilenumber")}
                  cursor="pointer"
                >
                  Date
                  {sortColumn === "vechilenumber" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                {/* <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("drivername")}
                  cursor="pointer"
                >
                  Image
                  {sortColumn === "drivername" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th> */}
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("outtime")}
                  cursor="pointer"
                >
                  Delivery From
                  {sortColumn === "outtime" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
              
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("outtime")}
                  cursor="pointer"
                >
                  Department
                  {sortColumn === "outtime" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("outtime")}
                  cursor="pointer"
                >
                  Sub Department
                  {sortColumn === "outtime" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("outtime")}
                  cursor="pointer"
                >
                  Parcel
                  {sortColumn === "outtime" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                {/* <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("outtime")}
                  cursor="pointer"
                >
                   Total parcel 
                  {sortColumn === "outtime" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th> */}
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("outtime")}
                  cursor="pointer"
                >
                  Status
                  {sortColumn === "outtime" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("intime")}
                  cursor="pointer"
                >
                   Handed Over
                  {sortColumn === "intime" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("intime")}
                  cursor="pointer"
                >
                   Pending
                  {sortColumn === "intime" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("outtime")}
                  cursor="pointer"
                >
                  In Time
                  {sortColumn === "outtime" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("outtime")}
                  cursor="pointer"
                >
                   Out Time
                  {sortColumn === "outtime" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("outtime")}
                  cursor="pointer"
                >
                   Total Time
                  {sortColumn === "outtime" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("intime")}
                  cursor="pointer"
                >
                   Last Status
                  {sortColumn === "intime" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {DelivaryInOutList ? (DelivaryInOutList.map((row, index, arr) => {
                
                return (
                  <CurrentTableRow
                  //  serialnumber={response.currentPage - 1 + index + 1}
                  serialnumber={totalItems - (((currentPage - 1) * limit) + index)}
                    name = {row.name}
                    date = {row.date}
                    image = {row.image}
                    contact = {row.mobileNo}
                    DeliveryFrom = {row.deliveryFrom.name}
                    Department = {row.departmentId.name}
                    SubDepartment = {row.subDepartmentId.name}
                    Parcel = {row.parcel}
                    Totalparcel ={row.parcelCountNo}
                    Status = {row.status}
                    inTime={row.inDateTime}
                    outTime={row.outDateTime}
                    isLast={index === arr.length - 1 ? true : false}
                    id={row._id}
                    handedOver={row.handedOver}
                    pending={row.pending}
                    lastStatus={row.lastStatus}
                    logText={row.logText}
                    totalTime={row.totalTime}
                  />
                );
              })) : (<h1></h1>)}
            </Tbody>
          </Table>}
          </div>
          {DelivaryInOutList.length === 0 ? (
            <></>
          ) : (
            <CurrentPagination
              totalPages={response.totalPages}
              currentPage={response.currentPage}
              perPage={response.perPage}
              sortBy={sortColumn}
              sortOrder={sortOrder}
              handlePageClick={(data) => {
                const selectedPage = data.selected + 1;
                dispatch(DelivaryInOutGetAll(`&page=${selectedPage}&type=IN`));
              }}
            />
          )}
        </CardBody>
      </Card>
    </Flex></>
  );
};

export default CurrentTable;
