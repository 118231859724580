import React, { useEffect, useState, useRef } from 'react';
import { Flex, Button, InputGroup, Spacer, Table, Tbody, Text, Td, Th, Thead, Tr, useColorModeValue, InputLeftElement, IconButton, Input, Icon, Link, SimpleGrid, FormControl, Center, Spinner, FormLabel, Select,useDisclosure } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';

import { useToast } from '@chakra-ui/react';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
// import DevicePagination from './DevicePagination';
import { AddIcon } from '@chakra-ui/icons';
import { IAMSAFE_CLEAR_SNACKBAR } from 'constants/types';
// import {  } from '../../actions/devicesAction'; // Assuming you have a DeviceAction file
// import DeviceTableRow from './DevicesTableRow';

// import DevicesPagination from './DevicesPagination';
// import iamSafePagination from './iamSafePagination'
import IamSafeTableRow from './IamSafeTableRow';
import { iamSafeGetAll } from 'actions/iamSafeAction';
import IamSafePagination from './IamSafePagination';
import axios from 'axios';
import { server_url } from "constants/types";
import { useHistory } from 'react-router-dom';
import IamSafeAdvanceSearch from './IamSafeAdvanceSearch';
import { Skeleton, SkeletonCircle, SkeletonText,Stack } from '@chakra-ui/react'
import Export from 'views/Export/Export';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

const {
  IAMSAFE_SEARCH
} = require('../../constants/types');
const IamSafeTable = (props) => {
  const { url = "", plant } = props;
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const responseStatus = useSelector(state => state.iamSafe.status);
  const responseMessage = useSelector(state => state.iamSafe.text);
  const loading = useSelector(state => state.iamSafe.loading);
  const iamSafeList = useSelector(state => state.iamSafe.iamSafeList);
  const response = useSelector(state => state.iamSafe.response);
  const showSnackbar = useSelector(state => state.iamSafe.showSnackbar);
  const totalItems = useSelector(state => state.iamSafe.totalItems);
  const dispatch = useDispatch();
  const toast = useToast();
  const searchIconColor = useColorModeValue("gray.700", "gray.200");
  const inputBg = useColorModeValue("#edf3f8", "navy.800");
  const [search, setSearch] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const history = useHistory();
  const tableRef = useRef(null);
  console.log("iamsafelist");
  console.log(iamSafeList);
  useEffect(() => {
    dispatch({
      type : IAMSAFE_SEARCH,
      payload : {
        searching : "",
      }
    })
    dispatch(iamSafeGetAll("&plant=" + plant));
  }, [dispatch]);

  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && !loading) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
        }
      } else if (responseStatus === 'NOK' && !loading) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }
    dispatch({
      type: IAMSAFE_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("desc");
    }
    dispatch({
      type : IAMSAFE_SEARCH,
      payload : {
        searching : "",
      }
    })
    const sorting = "&sortOrder=" + sortOrder + "&column=" + column;
    dispatch(deviceGetAll(sorting));
  };

  useEffect(() => {
    const searching = "&search=" + search;
    dispatch({
      type : IAMSAFE_SEARCH,
      payload : {
        searching : "",
      }
    })
    dispatch(iamSafeGetAll(searching));
  }, [search]);

  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, []);

  const [submittingLoader, setSubmittingLoader] = useState(false);
  const exportToExcel = async () => {
    setSubmittingLoader(true);
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet('Sheet 1');
    const filename = "Iamsafe_data_"+Date.now();
    // Define the headers
    const headers = [
      "SNo","Date", "Time","Type", "Name", "Phone No.",
      "Dept. & Sub-Dept.", "Blood Group", "Security Acknowledge"
    ];

    // Set up the columns with specific widths
    sheet.columns = [
      
      { header: 'SNo', key: 'sno', width: 15 },
      { header: 'Date', key: 'date', width: 15 },
      { header: 'Time', key: 'time', width: 20 },
      { header: 'Type', key: 'type', width: 20 },
      { header: 'Name', key: 'name', width: 20,}, // Adjust width as needed
      { header: 'Phone No.', key: 'phoneNo', width: 20 },
      { header: 'Dept. & Sub-Dept.', key: 'department', width: 20 },
      { header: 'Blood Group', key: 'bloodGroup', width: 20 },
      { header: 'Security Acknowledge', key: 'secirityAcknowledge', width: 20 }
    ];

    // Add the headers to the sheet
    headers.forEach((header, index) => {
      sheet.getCell(1, index + 1).value = header;
    });

    // Add data and images
    for (const [rowIndex, row] of iamSafeList.entries()) {
      const rowData = [
        totalItems - (((response.currentPage - 1) * response.limit) + rowIndex),
        row.date,
        row.time,
        row.guard ?'Guard':'Employee',
        row.employee?.employeeName ?? row.guard?.name,
        row.employee?.contactNo ?? row.guard?.number,
        row.employee?.department ?? row.guard.department,
        row.employee?.bloodGroup ?? row.guard.bloodGroup,
        row.isSeen ? 'True':'False'
      ];

      for (const [colIndex, cell] of rowData.entries()) {
        if(colIndex == 6)
        {
          sheet.getCell(rowIndex + 2, colIndex + 1).value = `${row.employee?.department?.name ?? row.guard?.department?.name}\n${row.employee?.subdepartment?.name ?? row.guard?.subdepartment?.name}`;
        }
        else
        {
          sheet.getCell(rowIndex + 2, colIndex + 1).value = cell;
        }
        
      }
    }

    // Set row height (if needed)
    for (let rowIndex = 1; rowIndex <= sheet.rowCount; rowIndex++) {
      sheet.getRow(rowIndex).height = 60; // Set height in points
    }

    // Generate Excel file and save
    const excelBuffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([excelBuffer]), `${filename}.xlsx`);
    setSubmittingLoader(false);
  };
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
    <IamSafeAdvanceSearch></IamSafeAdvanceSearch>
    <Flex direction="column" pt={{ base: "20", md: "30px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <Flex>
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              I-Am-Safe
            </Text>
          </CardHeader>
          <Spacer />

          {/* <Flex justify="flex-end" style={{ padding: '10px' ,marginTop : '-5px' ,marginRight :'12px' }}> 
            <Link href={`#/admin/patrolling-route-form`}>
            <IconButton
              colorScheme="blue"
              aria-label="Search database"
              icon={<AddIcon />}
            />
          </Link>
          </Flex> */}
          <Flex justify="flex-end" style={{ padding: '10px', marginTop: '-5px', marginRight: '12px' }}>
            {/* <DownloadTableExcel
              filename="I Am Safe Table"
              sheet="users"
              currentTableRef={tableRef.current}
            > */}
              <Button 
              loading={submittingLoader}
              onClick={onOpen}
              colorScheme="blue" _hover={{ backgroundColor: 'black', color: 'white' }}>
                Download
              </Button>
            {/* </DownloadTableExcel> */}
          </Flex>
          {/* Export */}
          <Export
            reportName = "I am safe Data"
            isOpen={isOpen}
            onClose={onClose}
            onExport={exportToExcel}
            />
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              <InputGroup borderRadius="8px" w="200px">
                <InputLeftElement
                  children={
                    <IconButton
                      bg="inherit"
                      borderRadius="inherit"
                      _hover="none"
                      _active={{
                        bg: "inherit",
                        transform: "none",
                        borderColor: "transparent",
                      }}
                      _focus={{
                        boxShadow: "none",
                      }}
                      icon={
                        <SearchIcon
                          color={searchIconColor}
                          w="15px"
                          h="15px"
                        />
                      }
                    ></IconButton>
                  }
                />
                <Input
                  variant="search"
                  fontSize="xs"
                  bg={inputBg}
                  onKeyUp={(e) => {
                    setSearch(e.target.value);
                    if (e.target.value.length < 3) {
                      setSearch("");
                    }
                  }}
                  placeholder="Type here..."
                />
              </InputGroup>
            </Text>
          </CardHeader>
        </Flex>
        <CardBody pb={4}>
          <div className='vertical-scroll' >
            
            {loading?<Stack>
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                  </Stack>:<Table variant="simple" color={textColor} ref={tableRef}>
              <Thead className='stick-header'>
                <Tr my=".8rem" pl="0px" color="gray.400">
                <Th
                    pl="15px"
                    borderColor={borderColor}
                    color="gray.400"
                    cursor="pointer"
                  >
                   SNO
                  </Th>
                <Th
                    pl="0px"
                    borderColor={borderColor}
                    color="gray.400"
                    cursor="pointer"
                  >
                    Date
                    {sortColumn === "name" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                <Th
                    pl="0px"
                    borderColor={borderColor}
                    color="gray.400"
                    cursor="pointer"
                  >
                    Time
                    {sortColumn === "name" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                <Th
                    pl="0px"
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("name")}
                    cursor="pointer"
                  >
                    Type
                    {sortColumn === "name" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    pl="0px"
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("name")}
                    cursor="pointer"
                  >
                    Name
                    {sortColumn === "name" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    pl="0px"
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("name")}
                    cursor="pointer"
                  >
                    Phone No.
                    {sortColumn === "name" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>

                  <Th
                    pl="0px"
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("name")}
                    cursor="pointer"
                  >
                    Dept. & Sub-Dept.
                    {sortColumn === "name" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  
                  <Th
                    pl="0px"
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("name")}
                    cursor="pointer"
                  >
                    bloodGroup
                    {sortColumn === "name" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    pl="0px"
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("name")}
                    cursor="pointer"
                  >
                    Security Acknowledge
                    {sortColumn === "name" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>

                  {/* <Th
                  pl="0px"
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("name")}
                  cursor="pointer"
                >
                 Vehicle Number
                  {sortColumn === "name" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th> */}

                  {/* <Th
                  pl="0px"
                  borderColor={borderColor}
                  color="gray.400"
                  onClick={() => handleSort("name")}
                  cursor="pointer"
                >
                 Photo
                  {sortColumn === "name" && (
                    <Icon
                      as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                      boxSize={4}
                      ml={1}
                    />
                  )}
                </Th>     */}

                <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {iamSafeList.length !== 0 ? (iamSafeList.map((row, index, arr) => {
                  
                  if (row.guard) {
                    return (
                      <IamSafeTableRow
                    // serialnumber={((response.currentPage - 1)*response.limit) + (index+1) }
                    serialnumber={totalItems - (((response.currentPage - 1) * response.limit) + index)}
                    date={row.date}
                      sosBy = {row.guard ? (<p>Guard</p>) : (<p>Employee</p>)}
                       name={row.guard.name}
                       number={row.guard.number}
                       data={row.date}
                       time={row.time}
                        passcode = {row.guard.passcode}
                        department={row.guard.department.name}
                        subdepartment={row.guard.subdepartment.name}
                        designation={row.guard.designation.name}
                        bloodGroup = {row.guard.bloodGroup}
                        isSeen={row.isSeen === true ?  (<p>True</p>) : (<p>False</p>)}
                        isLast={index === arr.length - 1 ? true : false}
                        id={row._id}
                        key={row._id}
                      />
                    )
                  } if (row.employee) {
                    return (
                      <IamSafeTableRow
                        // serialnumber={((response.currentPage - 1)*response.limit) + (index+1) }
                        serialnumber={totalItems - (((response.currentPage - 1) * response.limit) + index)}
                       sosBy = {row.employee ? (<p>Employee</p>) : (<p>Guard</p>)}
                        name={row.employee.employeeName}
                        number={row.employee.phone}
                        date={row.date}
                        time={row.time}
                        passcode = {row.employee.passcode}
                        department={row.employee.department.name}
                        subdepartment={row.employee.subdepartment.name}
                        designation={row.employee.designation.name}
                        bloodGroup = {row.employee.bloodGroup}
                        isSeen={row.isSeen === true ?  (<p>True</p>) : (<p>False</p>)}
                        isLast={index === arr.length - 1 ? true : false}
                        id={row._id}
                        key={row._id}
                      />

                    )
                  }
                })) : (<h1></h1>)
                }
              </Tbody>
            </Table>}
          </div>
          {iamSafeList.length === 0 ? (
            <></>
          ) : (
            <IamSafePagination
              totalPages={response.totalPages}
              currentPage={response.currentPage}
              perPage={response.perPage}
              sortBy={sortColumn}
              sortOrder={sortOrder}
              handlePageClick={(data) => {
                const selectedPage = data.selected + 1;
                dispatch(iamSafeGetAll(`&page=${selectedPage}`));
              }}
            />
          )}
        </CardBody>
      </Card>
    </Flex></>
  );
};

export default IamSafeTable;
