import React, { useEffect, useState, useRef } from 'react';
import { Flex, Button, InputGroup, Spacer, Table, Tbody, Text, Td, Th, Thead, Tr, useColorModeValue, InputLeftElement, IconButton, Input, Icon, Link, SimpleGrid, FormControl, Center, Spinner, FormLabel, Select,useDisclosure } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';

import { useToast } from '@chakra-ui/react';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import dataConstant from 'constants/dataConstant';
import { AddIcon } from '@chakra-ui/icons';
import { IAMSAFE_CLEAR_SNACKBAR ,VISITOR_SEARCH} from 'constants/types';
// import {  } from '../../actions/devicesAction'; // Assuming you have a DeviceAction file
// import DeviceTableRow from './DevicesTableRow';

// import DevicesPagination from './DevicesPagination';
// import iamSafePagination from './iamSafePagination'
import { VisitorInOutGetAll } from '../../../actions/VisitorInOutAction';
import axios from 'axios';
import { server_url } from "constants/types";
import { useHistory } from 'react-router-dom';
import VisitorInOutExport from '../VisitorInOutExport';
const PastAdvanceSearch = (props) => {
  const { url = "", plant } = props;
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const responseStatus = useSelector(state => state.visitorInOut.status);
  const responseMessage = useSelector(state => state.visitorInOut.text);
  const loading = useSelector(state => state.visitorInOut.loading);
  const searching = useSelector(state => state.visitorInOut.searching);
  // const iamSafeList = useSelector(state => state.iamSafe.iamSafeList);
  // const response = useSelector(state => state.iamSafe.response);
  const showSnackbar = useSelector(state => state.visitorInOut.showSnackbar);
  const dispatch = useDispatch();
  const toast = useToast();
  const searchIconColor = useColorModeValue("gray.700", "gray.200");
  const inputBg = useColorModeValue("#edf3f8", "navy.800");
  const history = useHistory();
  const tableRef = useRef(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [departmentId, setDepartmentId] = useState('');
  const [departmentList, setDepartmentList] = useState([]);
  const [visitorPurpose , setVisitorPurpose] = useState('');
  const [isErrorDepartment, setIsErrorDepartment] = useState(false);
const [visitorType , setVisitorType] =  useState('');
const [visitorTypeList , setVisitorTypeList] =  useState([]);
  const [subdepartment, setSubdepartment] = useState('');
  const [subdepartmentList, setSubdepartmentList] = useState([]);
  const [isErrorSubdepartment, setIsErrorSubdepartment] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [downloadLoader, setDownloadLoader] = useState(false);
  const [canteenCategory, setCanteenCategory] = useState('');
  const [canteenCategoryList, setCanteenCategoryList] = useState([]);
  useEffect(() => {
    departmentListforDropdown();
    canteenCategoryListforDropdown();
    visitorTypeDropdownList();
  }, [dispatch]);

  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && !loading) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
        }
      } else if (responseStatus === 'NOK' && !loading) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }
    dispatch({
      type: IAMSAFE_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };

  const visitorTypeDropdownList = () => {
    axios
      .get(`${server_url}/visitor-type`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        setVisitorTypeList(responce.data.data)
      }).catch(() => console.log("error"))
  }
  const departmentListforDropdown = () => {
    axios
      .get(`${server_url}/department`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        setDepartmentList(responce.data.data)
      }).catch(() => console.log("error"))
  }

  const subdepartmentListforDropdown = (depId) => {
    if (depId) {
      axios
        .get(`${server_url}/plant/subdepartment-list/${depId}`, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
          }
        }).then((responce) => {
          setSubdepartmentList(responce.data.data)
        })
    }
    else {
      setSubdepartmentList([])
    }

  }

  const canteenCategoryListforDropdown = () => {
    axios
      .get(`${server_url}/canteen/category?limit=1000`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        setCanteenCategoryList(responce.data.data)
      }).catch(() => console.log("error"))
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    var searching = "&startDate=" + startDate+"&endDate=" + endDate+"&department=" + departmentId+"&subdepartment=" + subdepartment+"&visitorPurpose=" + visitorPurpose + "&visitorType=" + visitorType; 
    dispatch({
      type : VISITOR_SEARCH,
      payload : {
        searching : searching,
      }
    })
    dispatch(VisitorInOutGetAll(searching+"&visitorStatus=EXIT&limit=50"));
    setSubmitted(false);
  };
  
  const handleClear = (event) => {
    event.preventDefault();
    setSubmitted(true);
    setStartDate('');
    setEndDate('');
    setDepartmentId('');
    setSubdepartment('');
    setVisitorPurpose('');
    setVisitorType("")
    const searchingLocal = "";
    dispatch({
      type : VISITOR_SEARCH,
      payload : {
        searching : searchingLocal,
      }
    })
    if(searching != searchingLocal)
      {
        dispatch(VisitorInOutGetAll(searchingLocal+"&visitorStatus=EXIT&limit=50"));
      }
    
  };
  const handleDownload = () => {
    
    setDownloadLoader(true);
    var searching = "&startDate=" + startDate+"&endDate=" + endDate+"&department=" + departmentId+"&subdepartment=" + subdepartment+"&visitorPurpose=" + visitorPurpose + "&visitorType=" + visitorType; 
    axios
      .get(`${server_url}/admin/visitor/export?visitorStatus=EXIT${searching}`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        },
        responseType: 'blob'
      }).then((response) => {
        const blob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
  
        // Create a link element to initiate the download
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `visitors_past_data${Date.now()}.xlsx`); // File name
  
        // Append the link to the body, trigger click and remove link
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setDownloadLoader(false);
      }).catch(() => console.log("error"))
      
  };
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
    <Flex direction="column" pt={{ base: "120px", md: "10px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Advance Search (Past)
          </Text>
          <SimpleGrid minChildWidth="120px" spacing="40px">
            <FormControl >
            <FormLabel>Start Date</FormLabel>
              <Input
                type="date"
                label="Bus No"
                value={startDate}
                onChange={(e) => {
                    setStartDate(e.target.value);
                }}
                
                 
              />
            </FormControl>
            <FormControl >
            <FormLabel>End Date</FormLabel>
              <Input
                type="date"
                min={startDate}
                value={endDate}
                onChange={(e) => {
                setEndDate(e.target.value);
    
                }}
                
                 
              />
            </FormControl>
           
            <FormControl >
              <FormLabel>Department</FormLabel>
              <Select
                placeholder="Department"
                onChange={(e) => {
                  setIsErrorDepartment(e.target.value === '');
                  setDepartmentId(e.target.value);
                  subdepartmentListforDropdown(e.target.value);
                }}
                value={departmentId}
              >
                {departmentList ? (departmentList.map((item, index) => (
                  <option key={item._id} value={item._id}>{item.name}</option>
                ))) : (<></>)}
              </Select>
              
            </FormControl>
          </SimpleGrid>

          <SimpleGrid minChildWidth="120px" spacing="40px" mt="40px">
            <FormControl >
              <FormLabel>Sub Department</FormLabel>
              <Select
                placeholder="Subdepartment"
                onChange={(e) => {
                  setIsErrorSubdepartment(e.target.value === '');
                  setSubdepartment(e.target.value);
                }}
                value={subdepartment}
              >
                {subdepartmentList ? (subdepartmentList.map((item, index) => (
                  <option key={item._id} value={item._id}>{item.name}</option>
                ))) : (<></>)}
              </Select>
              
            </FormControl>
            <FormControl >
              <FormLabel>Visitor Purpose</FormLabel>
              <Select
                placeholder="Visitor Purpose"
                onChange={(e) => {
                  
                  setVisitorPurpose(e.target.value);
                }}
                value={visitorPurpose}
              >
                {dataConstant.visitorPurpose ? (dataConstant.visitorPurpose.map((item, index) => (
                  <option key={item._id} value={item.value}>{item.name}</option>
                ))) : (<></>)}
              </Select>
              
            </FormControl>


            <FormControl >
              <FormLabel>Visitor Type</FormLabel>
              <Select
                placeholder="Visitor Type"
                onChange={(e) => {
                  setVisitorType(e.target.value);

                }}
                value={visitorType}
              >
                {visitorTypeList ? (visitorTypeList.map((item, index) => (
                  <option key={item._id} value={item._id}>{item.name}</option>
                ))) : (<></>)}
              </Select>
              
            </FormControl>
          </SimpleGrid>
          
          <Center>
            
                <>
                <Button
                isLoading={downloadLoader}
                colorScheme="blue" mt="24px" mr={"10px"} onClick={onOpen} >
                  Download
                </Button>
                <Button
                isLoading={loading}
                 colorScheme="blue"  mt="24px" onClick={handleSubmit} >
                Submit
              </Button>
              <Button colorScheme="green" mt="24px" ml="10px" onClick={ handleClear } >
                Reset
              </Button>
              </>
          </Center>
        </CardHeader>
        <CardBody></CardBody>
      </Card>
    </Flex>
    {/* Modal Component */}
    <VisitorInOutExport
    reportName = "Visitor In Out"
    isOpen={isOpen}
    onClose={onClose}
    onExport={() => 
    {
      handleDownload();
    }
    }
        />
    </>
  );
};

export default PastAdvanceSearch;
