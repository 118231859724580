import React, { useEffect, useState,useRef } from 'react';
import { Flex, InputGroup,Button, Spacer, Table, Tbody, Text, Td, Th, Thead, Tr, useColorModeValue, InputLeftElement, IconButton, Input, Icon, Link,useDisclosure } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';

import { useToast } from '@chakra-ui/react';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
// import DevicePagination from './DevicePagination';
import { AddIcon } from '@chakra-ui/icons';
import { MATERIAL_CLEAR_SNACKBAR ,MATERIAL_GATE_PASS_SEARCH} from 'constants/types';
// import {  } from '../../actions/devicesAction'; // Assuming you have a DeviceAction file
// import DeviceTableRow from './DevicesTableRow';

// import DevicesPagination from './DevicesPagination';
// import materialGatePassPagination from './materialGatePassPagination'
import MaterialGatePassTableRow from './MaterialGatePassTableRow';
import { materialGatePassGetAll } from '../../actions/materialGatePassAction';
import MaterialGatePassPagination from './MaterialGatePassPagination';

import axios from 'axios';
import { server_url } from "constants/types";
import { useHistory } from 'react-router-dom';
import MaterialGatePassAdvanceSearch from './MaterialGatePassAdvanceSearch';
import { Skeleton, SkeletonCircle, SkeletonText,Stack } from '@chakra-ui/react'
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import MaterialGatePassExport from './MaterialGatePassExport';
const MaterialGatePassTable = (props) => {
  const { url = "", plant } = props;
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const responseStatus = useSelector(state => state.materialGatePass.status);
  const responseMessage = useSelector(state => state.materialGatePass.text);
  const loading = useSelector(state => state.materialGatePass.loading);
  const materialGatePassList = useSelector(state => state.materialGatePass.materialGatePassList || []);
  const response = useSelector(state => state.materialGatePass.response);
  const showSnackbar = useSelector(state => state.materialGatePass.showSnackbar);
  const currentPage = useSelector(state => state.materialGatePass.page);
  const totalItems = useSelector(state => state.materialGatePass.totalItems);
  const limit = useSelector(state => state.materialGatePass.limit);
  const dispatch = useDispatch();
  const toast = useToast();
  const searchIconColor = useColorModeValue("gray.700", "gray.200");
  const inputBg = useColorModeValue("#edf3f8", "navy.800");
  const [search, setSearch] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [submittingLoader, setSubmittingLoader] = useState(false);
  const history = useHistory();
  const tableRef = useRef(null);
  console.log("materialGatePassList");
  console.log(materialGatePassList)
  useEffect(() => {
    dispatch(materialGatePassGetAll("&status=REJECTED_GATE_PASS&plant=" + plant));
    dispatch({
      type : MATERIAL_GATE_PASS_SEARCH,
      payload:{
        searching : ''
      }
    })
  }, [dispatch]);

  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && !loading) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
        }
      } else if (responseStatus === 'NOK' && !loading) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }
    dispatch({
      type: MATERIAL_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("desc");
    }
    const sorting = "&sortOrder=" + sortOrder + "&column=" + column;
    dispatch({
      type : MATERIAL_GATE_PASS_SEARCH,
      payload:{
        searching : ''
      }
    })
    dispatch(deviceGetAll(sorting));
  };

  const fetchImageAsBase64 = async (imageUrl) => {
    
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result.split(',')[1]); // Get base64 data
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const exportToExcel = async () => {
    setSubmittingLoader(true);
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet('Sheet 1');
    const filename = "Rejected_gatepass_data_"+Date.now();
    // Define the headers
    const headers = [
      "SNO", "Date", "Pass Type", "Mode", "Transaction Number", 
      "Gatepass Number", "Gatepass Photo", "Gate-pass Status", 
      "Department", "Sub Department", "Guard Status", "Logs"
    ];

    // Set up the columns with specific widths
    sheet.columns = [
      { header: 'SNO', key: 'sno', width: 10 },
      { header: 'Date', key: 'date', width: 15 },
      { header: 'Pass Type', key: 'passType', width: 20 },
      { header: 'Mode', key: 'mode', width: 20 },
      { header: 'Transaction Number', key: 'txn', width: 25 },
      { header: 'Gatepass Number', key: 'gatepassNumber', width: 25 },
      { header: 'Gatepass Photo', key: 'gatepassPhoto', width: 20 },
      { header: 'Gate-pass Status', key: 'gatepassStatus', width: 20 },
      { header: 'Department', key: 'department', width: 20 },
      { header: 'Sub Department', key: 'subDepartment', width: 20 },
      { header: 'Guard Status', key: 'guardStatus', width: 20 },
      { header: 'Logs', key: 'logs', width: 30 }
    ];

    // Add the headers to the sheet
    headers.forEach((header, index) => {
      sheet.getCell(1, index + 1).value = header;
    });

    // Add data and images
    for (const [rowIndex, row] of materialGatePassList.entries()) {
      const rowData = [
        totalItems - (((currentPage - 1) * limit) + rowIndex),
        row.date,
        row.passType,
        row.mode,
        row.txnNumber,
        row.gatepassNumber,
        row.gatepassPhoto, // Image URL
        row.gatepassStatus,
        row.department ? row.department.name : '',
        row.subdepartment ? row.subdepartment.name : '',
        row.guardStatus,
        row.logs[0]['description']
      ];

      for (const [colIndex, cell] of rowData.entries()) {
        if(row.imageBase64 == null)
          {
            sheet.getCell(rowIndex + 2, colIndex + 1).value = cell;
          }
        else if (colIndex === 6 && cell.startsWith('http')) { // Image column
          // const base64Image = await fetchImageAsBase64(cell);
          const base64Image = row.imageBase64;
          const imageId = workbook.addImage({
            base64: base64Image,
            extension: 'jpeg' // Change if your image is PNG
          });
          sheet.addImage(imageId, {
            tl: { col: colIndex, row: rowIndex + 1 }, // Top-left corner
            ext: { width: 60, height: 60 } // Resize image
          });
        } else {
          sheet.getCell(rowIndex + 2, colIndex + 1).value = cell;
        }
      }
    }

    // Set row height (if needed)
    for (let rowIndex = 1; rowIndex <= sheet.rowCount; rowIndex++) {
      sheet.getRow(rowIndex).height = 60; // Set height in points
    }

    // Generate Excel file and save
    const excelBuffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([excelBuffer]), `${filename}.xlsx`);
    setSubmittingLoader(false);
  };

  useEffect(() => {
    const searching = "&status=REJECTED_GATE_PASS&search=" + search;
    dispatch({
      type : MATERIAL_GATE_PASS_SEARCH,
      payload:{
        searching : ''
      }
    })
    dispatch(materialGatePassGetAll(searching));
  }, [search]);
  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, []);
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
    <MaterialGatePassAdvanceSearch></MaterialGatePassAdvanceSearch>
      <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <Flex>
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Rejected Material Gate Pass
            </Text>
          </CardHeader>
          <Spacer />
          {/* <Flex justify="flex-end" style={{ padding: '10px' ,marginTop : '-5px' ,marginRight :'12px' }}> 
            <Link href={`#/admin/patrolling-route-form`}>
            <IconButton
              colorScheme="blue"
              aria-label="Search database"
              icon={<AddIcon />}
            />
          </Link>
          </Flex> */}
          <Flex justify="flex-end" style={{ padding: '10px', marginTop: '-5px', marginRight: '12px' }}>
            {/* <DownloadTableExcel
              filename="Material Gate Pass Table"
              sheet="users"
              currentTableRef={tableRef.current}
            > */}
              <Button
              isLoading={submittingLoader}
              loadingText='Downloading'
               onClick={onOpen}
              colorScheme="blue" _hover={{ backgroundColor: 'black', color: 'white' }}>
                Download
              </Button>
            {/* </DownloadTableExcel> */}
          </Flex>
          {/* Export Modal */}
          <MaterialGatePassExport
            reportName = "Rejected Gate Pass Data"
            isOpen={isOpen}
            onClose={onClose}
            onExport={exportToExcel}
            />
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              <InputGroup borderRadius="8px" w="200px">
                <InputLeftElement
                  children={
                    <IconButton
                      bg="inherit"
                      borderRadius="inherit"
                      _hover="none"
                      _active={{
                        bg: "inherit",
                        transform: "none",
                        borderColor: "transparent",
                      }}
                      _focus={{
                        boxShadow: "none",
                      }}
                      icon={
                        <SearchIcon
                          color={searchIconColor}
                          w="15px"
                          h="15px"
                        />
                      }
                    ></IconButton>
                  }
                />
                <Input
                  variant="search"
                  fontSize="xs"
                  bg={inputBg}
                  onKeyUp={(e) => {
                    setSearch(e.target.value);
                    if (e.target.value.length < 3) {
                      setSearch("");
                    }
                  }}
                  placeholder="Type here..."
                />
              </InputGroup>
            </Text>
          </CardHeader>
        </Flex>
        <CardBody pb={4}>
          <div style={{ overflowX: "auto" }}>
          
            {loading ? <Stack>
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                  </Stack>:<Table className='vertical-scroll' variant="simple" color={textColor} ref={tableRef}>
              <Thead className='stick-header'>
                <Tr my=".8rem" pl="0px" color="gray.400">
                <Th
                    pl="15px"
                    borderColor={borderColor}
                    color="gray.400"
                    cursor="pointer"
                  >
                   SNO
                  </Th>
                  <Th
                    pl="0px"
                    borderColor={borderColor}
                    color="gray.400"
                    cursor="pointer"
                  >
                    Date
                    {sortColumn === "name" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    pl="0px"
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("name")}
                    cursor="pointer"
                  >
                    Pass Type
                    {sortColumn === "name" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("outtime")}
                    cursor="pointer"
                  >
                    Mode
                    {sortColumn === "outtime" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>

                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("outtime")}
                    cursor="pointer"
                  >
                    Transaction Number
                    {sortColumn === "outtime" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>

                  {/* <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("outtime")}
                    cursor="pointer"
                  >
                    status
                    {sortColumn === "outtime" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th> */}

                  {/* <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("outtime")}
                    cursor="pointer"
                  >
                    currentStatus
                    {sortColumn === "outtime" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th> */}
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("outtime")}
                    cursor="pointer"
                  >
                    gatepass Number
                    {sortColumn === "outtime" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("outtime")}
                    cursor="pointer"
                  >
                    Gatepass Photo
                    {sortColumn === "outtime" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("outtime")}
                    cursor="pointer"
                  >
                    gate-pass-Status
                    {sortColumn === "outtime" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>

                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("outtime")}
                    cursor="pointer"
                  >
                    Department
                    {sortColumn === "outtime" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("outtime")}
                    cursor="pointer"
                  >
                    sub department
                    {sortColumn === "outtime" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("outtime")}
                    cursor="pointer"
                  >
                    guard-Status
                    {sortColumn === "outtime" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("outtime")}
                    cursor="pointer"
                  >
                    Logs
                    {sortColumn === "outtime" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th></Th>

                </Tr>
              </Thead>
              <Tbody>
                {materialGatePassList ? (
                  materialGatePassList.map((row, index, arr) => {
              
                    return (
                      <MaterialGatePassTableRow
                       serialnumber={totalItems - (((currentPage - 1) * limit) + index)}
                        date={row.date}
                        passType={row.passType}
                        mode={row.mode}
                        status={row.status}
                        currentStatus={row.currentStatus}
                        gatepassNumber={row.gatepassNumber}
                        gatepassStatus={row.gatepassStatus}
                        guardStatus={row.guardStatus}
                        department={row.department ? row.department.name : <></>}
                        subdepartment={row.subdepartment ? row.subdepartment.name : <></>}
                        totalTime={row.totalTime}
                        txn={row.txnNumber}
                        logs={row.logs}
                        isLast={index === arr.length - 1 ? true : false}
                        id={row._id}
                        key={row._id}
                        gatepassPhoto= {row.gatepassPhoto}
                      />
                    );
                    
                  })
                ) : (
                  <h1></h1>
                )}

              </Tbody>
            </Table>}
          </div>
          {materialGatePassList.length === 0 ? (
            <></>
          ) : (
            <MaterialGatePassPagination
              totalPages={response.totalPages}
              currentPage={response.currentPage}
              perPage={response.perPage}
              sortBy={sortColumn}
              sortOrder={sortOrder}
              handlePageClick={(data) => {
                const selectedPage = data.selected + 1;
                dispatch(materialGatePassGetAll(`&status=REJECTED_GATE_PASS&page=${selectedPage}`));
              }}
            />
          )}
        </CardBody>
      </Card>
    </Flex>
    </>
  );
};

export default MaterialGatePassTable;
