var dataConstant = new Object();

///////// status type////
dataConstant.designationType = [
    {
        'id':1,
        'name': 'Employee',
        'value' : 'EMPLOYEE'
    },
    {
        'id':2,
        'name': 'Guard',
        'value' : "GUARD" 
    },
    {
        'id':3,
        'name': 'Driver',
        'value' : "DRIVER" 
    }
];

///////// status type////
dataConstant.status = [
    {
        'id':1,
        'name': 'Active',
        'value' : true
    },
    {
        'id':2,
        'name': 'Inactive',
        'value' : false 
    }
];

//////deliveryType
dataConstant.deliveryType = [
    {
        'id':1,
        'name': 'DELIVERY',
        'value' : 'DELIVERY'
    },
    {
        'id':2,
        'name': 'COURIER',
        'value' : 'COURIER' 
    }
];
dataConstant.statusType = [
    {
        'id':1,
        'name': 'RGP',
        'value' : "REJECTED_GATE_PASS"
    },
    {
        'id':2,
        'name': 'NRGP',
        'value' : "NRGP" 
    }
];

///////// boolean type////
dataConstant.boolean = [
    {
        'id':1,
        'name': 'true',
    },
    {
        'id':2,
        'name': 'false'
    }
];

///////// marital Status////
dataConstant.maritalstatus = [
    {
        'id':1,
        'name': 'Married',
    },
    {
        'id':2,
        'name': 'Un-Married',
    }
];

///////// Gender////
dataConstant.gender = [
    {
        'id':1,
        'name': 'Male',
    },
    {
        'id':2,
        'name': 'Female',
    },
    {
        'id':3,
        'name': 'Trans-Gender',
    }
];

///////// Validation  ////
dataConstant.validation = [
    {
        'id':1,
        'name': 1,
    },
    {
        'id':2,
        'name': 2,
    },
    {
        'id':3,
        'name': 3,
    },
    {
        'id':4,
        'name': 4,
    },
    {
        'id':5,
        'name': 5,
    },
    {
        'id':6,
        'name': 6,
    },
    {
        'id':7,
        'name': 7,
    }
];

///////// employee type////
dataConstant.requestType = [
    {
        'id':1,
        'name': 'Raise Request',
        'value' : 1
    },
    {
        'id':2,
        'name': 'Request Accept',
        'value' : 2
    },
   
];


///////// vehicle type////
dataConstant.vechilType = [
    {
        'id':1,
        'name': 'FOUR_WHEELER',
    },
    {
        'id':2,
        'name': 'TWO_WHEELER',
    },
    {
        'id':3,
        'name': 'BY_WALK',
    }
];

///////// ReportIncedent minor major////
dataConstant.reportIncedent = [
    {
        'id':1,
        'name': 'MINOR',
    },
    {
        'id':2,
        'name': 'MAJOR',
    },
   
];

dataConstant.passType = [
    {
        "id" : 1,
        'name' : "MATERIAL_GATE_PASS",
        "value" : "MATERIAL_GATE_PASS",
    },
    {
        "id" : 2,
        "name" : "RAISED_GATE_PASS",
        "value": "RAISED_GATE_PASS"
    }
]

dataConstant.mode = [
    {
        "id" : 1,
        'name' : "INCOMING_MATERIAL",
        "value" : "INCOMING_MATERIAL",
    },
    {
        "id" : 2,
        "name" : "OUTGOING_MATERIAL",
        "value": "OUTGOING_MATERIAL"
    }
]
dataConstant.materialShipmentType = [
    {
        "id" : 1,
        'name' : "RETURNABLE",
        "value" : "RETURNABLE",
    },
    {
        "id" : 2,
        "name" : "NON_RETURNABLE",
        "value": "NON_RETURNABLE"
    }
]
dataConstant.weighmentStatus = [
    {
        "id" : 1,
        'name' : "PENDING",
        "value" : "PENDING",
    },
    {
        "id" : 2,
        "name" : "WEIGHMENT-1",
        "value": "WEIGHMENT1"
    },
    {
        "id" : 3,
        "name" : "WEIGHMENT-2",
        "value": "WEIGHMENT2"
    }
]

dataConstant.OutPassReason = [
    {
        "id" : 1,
        "name" : "Medical-Emergency",
        "value" : "Medical emergency",
    },
    {
        "id" : 2,
        "name" : "Personal",
        "value" : "Personal",
    },
    {
        "id" : 3,
        "name" : "Official-Purpose",
        "value" : "Official purpose",
    },
    {
        "id" : 4,
        "name" : "Others",
        "value" : "Others",
    }
]
dataConstant.visitorPurpose = [
    {
        "id" : 1,
        "name" : "Interview",
        "value" : "Interview",
    },
    {
        "id" : 2,
        "name" : "Meeting",
        "value" : "Meeting",
    },
    {
        "id" : 3,
        "name" : "Officical Visit",
        "value" : "Officical Visit",
    },
    {
        "id" : 4,
        "name" : "Others",
        "value" : "Others",
    }
]

dataConstant.outPassDuration = [
    {
        "id" : 1,
        "name" : "1hr",
        "value" : "1hr",
    },
    {
        "id" : 2,
        "name" : "2hrs",
        "value" : "2hrs",
    },
    {
        "id" : 3,
        "name" : "Half-Day",
        "value" : "Half Day",
    },
    {
        "id" : 4,
        "name" : "Full-Day",
        "value" : "Full Day",
    }
]
///// Banner Type
dataConstant.bannerType = [
    {
        'id':1,
        'name': 'Guard',
        'value' : 'guard'
    },
    {
        'id':2,
        'name': 'Employee',
        'value' : 'employee' 
    }
];

dataConstant.type = [
    {
        'id':1,
        'name': 'popular',
        'value' : "popular"
    },
    {
        'id':2,
        'name': 'unpopular',
        'value' : "unpopular" 
    }
];

///// Access app
dataConstant.accessApp = [
    {
        'id':1,
        'name': 'Enable',
        'value' : true
    },
    {
        'id':2,
        'name': 'Disable',
        'value' : false 
    }
];
///// Call type
dataConstant.callTypeList = [
    {
        'id':1,
        'name': 'Primary',
        'value' : "PRIMARY"
    },
    {
        'id':2,
        'name': 'Secondary',
        'value' : "SECONDARY" 
    },
    {
        'id':3,
        'name': 'Not Assigned',
        'value' : "NOTASSIGNED" 
    }
];

///// Enable Notification
dataConstant.notification = [
    {
        'id':1,
        'name': 'Enable',
        'value' : true
    },
    {
        'id':2,
        'name': 'Disable',
        'value' : false 
    }
];

///// Blood Group
dataConstant.bloodGroup = [
    {
        'id':1,
        'name': 'A+',
        'value' : 'A+'
    },
    {
        'id':2,
        'name': 'A-',
        'value' : 'A-' 
    },
    {
        'id':3,
        'name': 'B+',
        'value' : 'B+' 
    },
    {
        'id':4,
        'name': 'B-',
        'value' : 'B-' 
    },
    {
        'id':5,
        'name': 'O+',
        'value' : 'O+' 
    },
    {
        'id':6,
        'name': 'O-',
        'value' : 'O-' 
    },
    {
        'id':7,
        'name': 'AB+',
        'value' : 'AB+' 
    },
    {
        'id':8,
        'name': 'AB-',
        'value' : 'AB-' 
    },
];

///// Blood Group
dataConstant.employeeType = [
    {
        'id':1,
        'name': 'Part-time employees',
        'value' : 1
    },
    {
        'id':2,
        'name': 'Full-time employees',
        'value' : 2
    },
    {
        'id':3,
        'name': 'Contractual',
        'value' : 3
    },
    {
        'id':4,
        'name': 'Intern',
        'value' : 4 
    },
];

dataConstant.vehicleType = [
    {
        'id':1,
        'name': 'BY WALK',
        'value' : 'BY_WALK',
        'image' : ""
    },
    {
        'id':2,
        'name': 'TWO WHEELER',
        'value' : 'TWO_WHEELER',
        'image' : "https://firebasestorage.googleapis.com/v0/b/kavach-77a40.appspot.com/o/images%2FFrame%2058.png?alt=media&token=7419b4e3-1d1c-45d5-aad5-14e58664ff6d"
    },
    {
        'id':3,
        'name': 'FOUR WHEELER',
        'value' : 'FOUR_WHEELER',
        'image' : "https://firebasestorage.googleapis.com/v0/b/kavach-77a40.appspot.com/o/images%2FFrame%2059.png?alt=media&token=96d7538a-6c1f-4315-98e0-873c1aeb9071"
    },
    {
        'id':4,
        'name': 'BUS',
        'value' : 'BUS',
        'image' : "https://firebasestorage.googleapis.com/v0/b/kavach-77a40.appspot.com/o/images%2FVector.png?alt=media&token=03cc6a57-4013-4473-80da-b0d8bd5f2b8a"
    },
    {
        'id':5,
        'name': 'UNLOADED BAYS',
        'value' : 'UNLOADED_BAYS',
        'image' : "https://firebasestorage.googleapis.com/v0/b/kavach-77a40.appspot.com/o/images%2FFrame%2059%20(1).png?alt=media&token=8236de32-c303-43b4-b6fd-50b995537b83"
    },
    {
        'id':6,
        'name': 'LOADED BAYS',
        'value' : 'LOADED_BAYS',
        'image' : "https://firebasestorage.googleapis.com/v0/b/kavach-77a40.appspot.com/o/images%2FFrame%2058%20(1).png?alt=media&token=9a88e2ae-acf7-4fde-a5c7-0f84c02dc011"
    },
];

dataConstant.transportType = [
    {
        'id':1,
        'name': 'TRANSPORT',
    },
    {
        'id':2,
        'name': 'INTERCARTING',
    },
   
];

dataConstant.parkingDepartment = [
    {
        'id':1,
        'name': 'Transport',
        'value' : 'TRANSPORT'

    },
    {
        'id':2,
        'name': 'Intercarting',
        'value' : 'INTERCARTING'
    },
    {
        'id':3,
        'name': 'Employee',
        'value' : 'EMPLOYEE'
    },
    {
        'id':4,
        'name': 'Visitor',
        'value' : 'VISITOR'
    },
    {
        'id':5,
        'name': 'Material',
        'value' : 'MATERIAL'
    },
    {
        'id':6,
        'name': 'Delivery',
        'value' : 'DELIVERY'
    },
    {
        'id':7,
        'name': 'Canteen',
        'value' : 'CANTEEN'
    },
   
];

///////// Department head type////
dataConstant.departmentHead = [
    {
        'id':1,
        'name': 'Yes',
        'value' : true,
    },
    {
        'id':2,
        'name': 'No',
        'value' : false
    }
];

dataConstant.homeModule = [
    {
        'id':1,
        'name' : 'Passcode',
        'value': 'PASSCODE',
    },
    {
        'id':2,
        'name' : 'Mobile Number',
        'value': 'MOBILE_NUMBER',
    },
    {
        'id':3,
        'name' : 'Walkie Talkie',
        'value': 'WALKIE_TALKIE',
    },
    {
        'id':4,
        'name' : 'Vehicle Number',
        'value': 'VEHICLE_NUMBER',
    },
    {
        'id':4,
        'name' : 'OTP',
        'value': 'OTP',
    },
   
];
// for biometric
dataConstant.biometricType = [
    {
        'id':1,
        'name': 'All',
        'value' : 'ALL'
    },
    {
        'id':1,
        'name': 'Employee',
        'value' : 'EMPLOYEE'
    },
    {
        'id':2,
        'name': 'Guard',
        'value' : "GUARD" 
    },
    {
        'id':3,
        'name': 'Driver',
        'value' : "DRIVER" 
    }
];

dataConstant.biometricAuthType = [
    {
        'id':1,
        'name': 'No Auth',
        'value' : 'No Auth'
    },
    {
        'id':2,
        'name': 'Basic Auth',
        'value' : 'Basic Auth'
    },
    {
        'id':3,
        'name': 'Bearer token',
        'value' : "Bearer token" 
    },
    {
        'id':4,
        'name': 'Digest Auth',
        'value' : "Digest Auth" 
    },
    {
        'id':5,
        'name': 'OAuth',
        'value' : "OAuth" 
    },
    {
        'id':6,
        'name': 'API Key',
        'value' : "API Key" 
    }
];

dataConstant.biometricTimeInterval = [
    {
        'id':1,
        'name': '05 sec',
        'value' : 5
    },
    {
        'id':2,
        'name': '10 sec',
        'value' : 10 
    },
    {
        'id':3,
        'name': '15 sec',
        'value' : 15 
    },
    {
        'id':4,
        'name': '20 sec',
        'value' : 20 
    },
    {
        'id':5,
        'name': '25 sec',
        'value' : 25 
    },
    {
        'id':6,
        'name': '30 sec',
        'value' : 30 
    },
    {
        'id':7,
        'name': '35 sec',
        'value' : 35 
    },
    {
        'id':8,
        'name': '40 sec',
        'value' : 40 
    },
    {
        'id':9,
        'name': '45 sec',
        'value' : 45 
    },
    {
        'id':10,
        'name': '50 sec',
        'value' : 50 
    },
    {
        'id':11,
        'name': '55 sec',
        'value' : 55 
    },
    {
        'id':12,
        'name': '60 sec',
        'value' : 60 
    },
    {
        'id':13,
        'name': '30 min',
        'value' : 1800 
    },
    {
        'id':13,
        'name': '1 hour',
        'value' : 3600 
    }
];
dataConstant.EMPLOYEE_OUTPASSAUTH = '64e5b5440bee8106e30856e3';
dataConstant.EMPLOYEE_GATEPASSAUTH = '64e5b5720bee8106e3085705';
// dataConstant.EMPLOYEE_PREAPPROVAL = '6682a2194c916a22248cfb80'; // for testing database
dataConstant.EMPLOYEE_PREAPPROVAL = '6683dbd62cf2d301c7f50546';   // For Live database
// Group Role constant
dataConstant.GROUPROLE = 'Group'; 
// dataConstant.CORPORATEROLE = '66c6c40f6ed49212bc64ad28'; //for testing db
dataConstant.CORPORATEROLE = '66c854cd6ed49212bc64ad2c'; //for live db

///////// status Address Proof////
dataConstant.idProof = [
    {
        'id':1,
        'name': 'Pan card',
        'value' : 'Pan card'
    },
    {
        'id':2,
        'name': 'Aadhar card',
        'value' : 'Aadhar card', 
    },
    {
        'id':3,
        'name': 'Driving license',
        'value' : 'Driving license', 
    }
];

// Inspection Duration
///////// Validation  ////
dataConstant.inspectionDuration = [
    {
        'id':1,
        'name': "Daily",
        'value': "Daily",
    },
    {
        'id':2,
        'name': "7 Days",
        'value': "7 Days",
    },
    {
        'id':3,
        'name': "15 Days",
        'value': "15 Days",
    },
    {
        'id':4,
        'name': "30 Days",
        'value': "30 Days",
    },
    {
        'id':5,
        'name': "90 Days",
        'value': "90 Days",
    },
    {
        'id':6,
        'name': "180 Days",
        'value': "180 Days",
    },
    {
        'id':7,
        'name': "360 Days",
        'value': "360 Days",
    }
];
// Meeting Room Type
dataConstant.meetingRoomType = [
    {
        'id':1,
        'name': "Common",
        'value': "COMMON",
    },
    {
        'id':2,
        'name': "Internal",
        'value': "INTERNAL",
    }
];
// Meeting Room Approval Required
dataConstant.meetingRoomApproval = [
    {
        'id':1,
        'name': "Yes",
        'value': "YES",
    },
    {
        'id':2,
        'name': "No",
        'value': "NO",
    }
];
// Meeting Room Amenities
dataConstant.meetingRoomAmenities = [
    {
        'id':1,
        'name': "Projector",
        'value': "Projector",
    },
    {
        'id':2,
        'name': "Whiteboards",
        'value': "Whiteboards",
    },
    {
        'id':3,
        'name': "Wi-Fi access",
        'value': "Wi-Fi access",
    },
    {
        'id':4,
        'name': "Notepads and pens",
        'value': "Notepads and pens",
    }
];

// Report Authentation Reason
dataConstant.reportAuthenticationReason = [
    {
        id: 1,
        name: "Access for Emergency Protocols",
        value: "Access for Emergency Protocols",
    },
    {
        id: 2,
        name: "Audit Requirement",
        value: "Audit Requirement",
    },
    {
        id: 3,
        name: "Compliance Reporting",
        value: "Compliance Reporting",
    },
    {
        id: 4,
        name: "Data Correction/Update",
        value: "Data Correction/Update",
    },
    {
        id: 5,
        name: "Employee Verification",
        value: "Employee Verification",
    },
    {
        id: 6,
        name: "Health & Safety Audit",
        value: "Health & Safety Audit",
    },
    {
        id: 7,
        name: "Inspection Preparation",
        value: "Inspection Preparation",
    },
    {
        id: 8,
        name: "Internal Quality Check",
        value: "Internal Quality Check",
    },
    {
        id: 9,
        name: "Management Review",
        value: "Management Review",
    },
    {
        id: 10,
        name: "Operational Review",
        value: "Operational Review",
    },
    {
        id: 11,
        name: "Performance Monitoring",
        value: "Performance Monitoring",
    },
    {
        id: 12,
        name: "Regulatory Reporting",
        value: "Regulatory Reporting",
    },
    {
        id: 13,
        name: "Security Incident Investigation",
        value: "Security Incident Investigation",
    },
    {
        id: 14,
        name: "Visitor Verification",
        value: "Visitor Verification",
    },
    {
        id: 15,
        name: "Other (Specify)",
        value: "Other",
    }
];

dataConstant.reportAuthenticationOthers = 'Other'; 

// Multi Auth Matrix Type Constant 
dataConstant.multiauthmatrixTypeConstant = [
    {
        'id':1,
        'name': "RGP",
        'value': "RGP",
    },
    {
        'id':2,
        'name': "NRGP",
        'value': "NRGP",
    },
    {
        'id':3,
        'name': "REJECTED",
        'value': "REJECTED",
    },
    {
        'id':4,
        'name': "OUTPASS",
        'value': "OUTPASS",
    },
    {
        'id':4,
        'name': "MEETINGROOM",
        'value': "MEETINGROOM",
    }
];

dataConstant.multiAuthFirstAuth = 'First Authorization'; 
dataConstant.multiAuthSecondAuth = 'Second Authorization'; 
dataConstant.multiAuthThirdAuth = 'Third Authorization'; 
dataConstant.multiAuthFourthAuth = 'Fourth Authorization'; 
dataConstant.multiAuthFifthAuth = 'Fifth Authorization'; 

export default dataConstant;