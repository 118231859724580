// Chakra imports
import {
  Flex,
  InputGroup,
  Spacer,
  Table,
  Tbody,
  Button,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  InputLeftElement,
  IconButton,
  Input,
  Icon,
  Link,
  useDisclosure
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";

import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useToast } from "@chakra-ui/react";
import {
  reportIncedentGetAll,
  reportIncedentDelete,
  reportIncedentAddOrUpdate,
  reportIncedentClearShowSnackbar,
} from "../../actions/reportIncedentAction";
import { REPORTINCEDENT_CLEAR_SNACKBAR  ,REPORTINCEDENT_SEARCH} from "constants/types";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useHistory,useLocation } from "react-router-dom";
import axios from "axios";
import { server_url } from "../../constants/types";
import { DownloadTableExcel } from "react-export-table-to-excel";

import { AddIcon } from "@chakra-ui/icons";

import ReportIncedentPagination from "./RedportIncedentPagination";
import ReportIncedentTableRow from "./RedportIncedentTableRow";
import ReportIncidentAdvanceSearch from "./ReportIncidentAdvanceSearch";
import { Skeleton, SkeletonCircle, SkeletonText,Stack } from '@chakra-ui/react'
import Dashboard from "./Dashboard";
import Export from "views/Export/Export";
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

function ReportIncedentTable() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const responseStatus = useSelector((state) => state.reportIncedent.status);
  const responseMessage = useSelector((state) => state.reportIncedent.text);
  const loading = useSelector((state) => state.reportIncedent.loading);
  const currentPage = useSelector(state => state.reportIncedent.page);
  const totalItems = useSelector(state => state.reportIncedent.totalItems);
  const limit = useSelector(state => state.reportIncedent.limit);
  const reportIncedentList = useSelector(
    (state) => state.reportIncedent.reportIncedentList
  );
  const response = useSelector((state) => state.reportIncedent.response);
  const showSnackbar = useSelector(
    (state) => state.reportIncedent.showSnackbar
  );
  const dispatch = useDispatch();
  const toast = useToast();
  const history = useHistory();
  const searchIconColor = useColorModeValue("gray.700", "gray.200");
  const inputBg = useColorModeValue("#edf3f8", "navy.800");
  const [search, setSearch] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const tableRef = useRef(null);
  const location = useLocation();
  console.log("reportIncedentList123456");
  console.log(reportIncedentList);
  const [typeName, setTypeName] = useState("All Report");
  useEffect(() => {
    dispatch(reportIncedentGetAll());
    dispatch({
      type : REPORTINCEDENT_SEARCH,
      payload : {
        searching : ""
      }
    })
  }, [dispatch]);
  const query = new URLSearchParams(location.search);
  useEffect(() => {
    var checkName = query.get('typeName');
    if(checkName == null)
      {
        setTypeName("All Report");
      }
      else
      {
        setTypeName(checkName);
      }
    
  }, [query]);
  
  
  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === "OK" && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, "success");
        }
      } else if (responseStatus === "NOK" && loading === false) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, "error");
        }
      }
    }
    dispatch({
      type: REPORTINCEDENT_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      },
    });
  }, [loading]);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    });
  };

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("desc");
    }
    const sorting = "&sortOrder=" + sortOrder + "&column=" + column;
    dispatch({
      type : REPORTINCEDENT_SEARCH,
      payload : {
        searching : ""
      }
    })
    dispatch(reportIncedentGetAll(sorting));
  };

  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("authToken"),
        },
      })
      .then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push("/auth/signin");
      });
  }, []);

  useEffect(() => {
    const searching = "&search=" + search;
    console.log(searching);
    dispatch({
      type : REPORTINCEDENT_SEARCH,
      payload : {
        searching : ""
      }
    })
    dispatch(reportIncedentGetAll(searching));
  }, [search]);

  const [submittingLoader, setSubmittingLoader] = useState(false);
  const exportToExcel = async () => {
    setSubmittingLoader(true);
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet('Sheet 1');
    const filename = "Incident_report_data_"+Date.now();
    // Define the headers
    const headers = [
      "SNo","Date", "Time", "Type of Incident", "Location", "Category",  "Reported By"
    ];

    // Set up the columns with specific widths
    sheet.columns = [
      
      { header: 'SNo', key: 'sno', width: 15 },
      { header: 'Date', key: 'date', width: 15 },
      { header: 'Time', key: 'time', width: 20 },
      { header: 'Type of Incident', key: 'typeOfIncident', width: 20,},
      { header: 'Location', key: 'location', width: 20 },
      { header: 'Category', key: 'category', width: 20 },
      { header: 'Reported By', key: 'reportedBy', width: 20 }
    ];

    // Add the headers to the sheet
    headers.forEach((header, index) => {
      sheet.getCell(1, index + 1).value = header;
    });

    // Add data and images
    for (const [rowIndex, row] of reportIncedentList.entries()) {
      const rowData = [
        totalItems - (((currentPage - 1) * limit) + rowIndex),
        row.date,
        row.time,
        row.typeOfIncident.map((ele) => ele.name),
        row.locationOfIncident,
        row.type,
        row.guard?.name
      ];

      for (const [colIndex, cell] of rowData.entries()) {
        sheet.getCell(rowIndex + 2, colIndex + 1).value = cell;
      }
    }

    // Set row height (if needed)
    for (let rowIndex = 1; rowIndex <= sheet.rowCount; rowIndex++) {
      sheet.getRow(rowIndex).height = 60; // Set height in points
    }

    // Generate Excel file and save
    const excelBuffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([excelBuffer]), `${filename}.xlsx`);
    setSubmittingLoader(false);
  };
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
    <Dashboard></Dashboard>
    <ReportIncidentAdvanceSearch></ReportIncidentAdvanceSearch>
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <Flex>
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              {typeName} <span style={{
                color:"red"
              }}>({totalItems})</span>
            </Text>
          </CardHeader>
          <Spacer />

          {/* <Flex justify="flex-end" style={{ padding: '10px' ,marginTop : '-5px' ,marginRight :'12px' }}> 
                    <Link href={`#/admin/reportIncedent-form`}>
                    <IconButton
                      colorScheme="blue"
                      aria-label="Search database"
                      icon={<AddIcon />}
                    />
                    </Link>
            </Flex> */}
          <Flex
            justify="flex-end"
            style={{ padding: "10px", marginTop: "-5px", marginRight: "12px" }}
          >
            {/* <DownloadTableExcel
              filename="Report Incedent Table"
              sheet="users"
              currentTableRef={tableRef.current}
            > */}
              <Button
              loading={submittingLoader}
              onClick={onOpen}
                colorScheme="blue"
                _hover={{ backgroundColor: "black", color: "white" }}
              >
                Export Excel
              </Button>
            {/* </DownloadTableExcel> */}
          </Flex>
          {/* Export */}
          <Export
            reportName = "Incident Report"
            isOpen={isOpen}
            onClose={onClose}
            onExport={exportToExcel}
            />

          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              <InputGroup borderRadius="8px" w="200px">
                <InputLeftElement
                  children={
                    <IconButton
                      bg="inherit"
                      borderRadius="inherit"
                      _hover="none"
                      _active={{
                        bg: "inherit",
                        transform: "none",
                        borderColor: "transparent",
                      }}
                      _focus={{
                        boxShadow: "none",
                      }}
                      icon={
                        <SearchIcon color={searchIconColor} w="15px" h="15px" />
                      }
                    ></IconButton>
                  }
                />

                <Input
                  variant="search"
                  fontSize="xs"
                  bg={inputBg}
                  onKeyUp={(e) => {
                    setSearch(e.target.value);
                    if (e.target.value.length < 3) {
                      setSearch("");
                    }
                  }}
                  placeholder="Type here..."
                />
              </InputGroup>
            </Text>
          </CardHeader>
        </Flex>
        <CardBody pb={4}>
          <div style={{ overflowX: "auto",height:'500px' }}>
            {loading ? <Stack>
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                  </Stack>:<Table variant="simple" color={textColor} ref={tableRef}>
              <Thead className="stick-header">
                <Tr my=".8rem" pl="0px" color="gray.400">
                <Th
                    pl="15px"
                    borderColor={borderColor}
                    color="gray.400"
                    cursor="pointer"
                  >
                   SNO
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("description")}
                    cursor="pointer"
                  >
                    Date
                    {sortColumn === "description" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("description")}
                    cursor="pointer"
                  >
                    time
                    {sortColumn === "description" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  

                  <Th
                    pl="0px"
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("name")}
                    cursor="pointer"
                  >
                    Type Of incident
                    {sortColumn === "name" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("image")}
                    cursor="pointer"
                  >
                    Location
                    {sortColumn === "image" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("image")}
                    cursor="pointer"
                  >
                    Category
                    {sortColumn === "image" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("image")}
                    cursor="pointer"
                  >
                    Photo
                    {sortColumn === "image" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>

                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("image")}
                    cursor="pointer"
                  >
                    Reported By
                    {sortColumn === "image" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>

                  <Th borderColor={borderColor}></Th>
                  {/* <Th borderColor={borderColor}></Th> */}
                </Tr>
              </Thead>
              <Tbody>
                {reportIncedentList ? (
                  reportIncedentList.map((row, index, arr) => (
                    <ReportIncedentTableRow
                       serialnumber={totalItems - (((currentPage - 1) * limit) + index)}
                       date={row.date}
                       time={row.time}
                      typeofincident={row.typeOfIncident.map((ele) => ele.name)}
                      description={row.description}
                      reason={row.reason}
                      nameOfPersonInvolved={row.nameOfThePersonInvolved}
                      locationOfIncident={row.locationOfIncident}
                      createdBy={row.createdBy}
                      reportedBy={row.guard?.name}
                      type={row.type}
                      image={row.image[0]}
                      isLast={index === arr.length - 1 ? true : false}
                      id={row._id}
                    />
                  ))
                ) : (
                  <h1></h1>
                )}
              </Tbody>
            </Table>}
          </div>
          {reportIncedentList.length === 0 ? (
            <></>
          ) : (
            <ReportIncedentPagination
              totalPages={response.totalPages}
              currentPage={response.currentPage}
              perPage={response.perPage}
              sortBy={sortColumn}
              sortOrder={sortOrder}
              handlePageClick={(data) => {
                const selectedPage = data.selected + 1;
                dispatch(reportIncedentGetAll(`&page=${selectedPage}`));
              }}
            />
          )}
        </CardBody>
      </Card>
    </Flex></>
  );
}

export default ReportIncedentTable;
