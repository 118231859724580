import React, { useEffect, useState,useRef } from 'react';
import { Flex, InputGroup, Spacer, Table, Tbody, Text, Td, Th, Thead, Tr, useColorModeValue, InputLeftElement, IconButton, Input, Icon, Link ,Button, Tabs, TabList, TabPanels, TabPanel, Tab,Spinner,Center,useDisclosure,Tooltip} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';

import { useToast } from '@chakra-ui/react';

import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';

import { AddIcon,InfoOutlineIcon } from '@chakra-ui/icons';
import { VISITORINOUT_CLEAR_SNACKBAR ,INSPECTION_SUMMARY_SEARCH,INSPECTION_SUMMARY_CLEAR} from 'constants/types';

import AllTableRow from './AllTableRow';
import { inspectionSummaryListGetAll } from '../../../actions/inspectionsummaryAction';
// import PastPagination from './PastPagination';
import AllAdvanceSearch from "./AllAdvanceSearch"
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { Skeleton, SkeletonCircle, SkeletonText,Stack } from '@chakra-ui/react'
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import axios from 'axios';
import { server_url } from "constants/types";
import InspectionExport from '../InspectionExport';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Export from 'views/Export/Export';
const AllTable = (props) => {
    const {
        schedule=""
      } = props;
  const tableRef = useRef(null);
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const responseStatus = useSelector(state => state.inspectionSummary.status);
  const responseMessage = useSelector(state => state.inspectionSummary.text);
  const loading = useSelector(state => state.inspectionSummary.loading);
  const inspectionList = useSelector(state => state.inspectionSummary.inspectionList);
  const response = useSelector(state => state.inspectionSummary.response);
  const showSnackbar = useSelector(state => state.inspectionSummary.showSnackbar);
  const currentPage = useSelector(state => state.inspectionSummary.page);
  const totalItems = useSelector(state => state.inspectionSummary.totalItems);
  const limit = useSelector(state => state.inspectionSummary.limit);
  const searching = useSelector(state => state.inspectionSummary.searching);
  const dispatch = useDispatch();
  const toast = useToast();
  const searchIconColor = useColorModeValue("gray.700", "gray.200");
  const inputBg = useColorModeValue("#edf3f8", "navy.800");
  const [search, setSearch] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [submittingLoader, setSubmittingLoader] = useState(false);

  // lazy loading
  const [page, setPage] = useState(1);
  const [isFetching, setIsFetching] = useState(false);
  const [newInspectionData, setNewInspectionData] = useState([]);
  const [startAppending, setStartAppending] = useState(false);
  const authDetail = useSelector(state => state.auth.authDetail);
  const searchStartDate = useSelector(state => state.inspectionSummary.startDate);
  const searchEndDate = useSelector(state => state.inspectionSummary.endDate);



  useEffect(() => {
    dispatch(inspectionSummaryListGetAll(`&inspectionSchedule=${schedule}&page=${page}&limit=50`));
    dispatch({
      type : INSPECTION_SUMMARY_SEARCH,
      payload : {
        searching : '',
      }
    })
  }, []);
  console.log("inspectionList------")
  console.log(inspectionList)

  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && !loading) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
        }
      } else if (responseStatus === 'NOK' && !loading) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }
    dispatch({
      type: VISITORINOUT_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("desc");
    }
    const sorting = "&inspectionSchedule="+schedule+"&sortOrder=" + sortOrder + "&column=" + column+"&limit=50";
    dispatch({
      type : INSPECTION_SUMMARY_SEARCH,
      payload : {
        searching : '',
      }
    })
    dispatch(inspectionSummaryListGetAll(sorting));
  };

  const handelSearch = (search) => {
    const searching = "&inspectionSchedule="+schedule+"&search=" + search+"&limit=50";
    dispatch({
      type : INSPECTION_SUMMARY_SEARCH,
      payload : {
        searching : '',
      }
    })
    dispatch(inspectionSummaryListGetAll(searching));
    setNewInspectionData([]);
    setPage(1);
  }
  const fetchImageAsBase64 = async (imageUrl) => {
    const response = await fetch(imageUrl,{ mode: 'cors' });
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      // console.log(reader);
      // console.log("reader");
      reader.onloadend = () => resolve(reader.result.split(',')[1]); // Get base64 data
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const exportToExcel = async () => {
    setSubmittingLoader(true);
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet('Sheet 1');
    const filename = "Past Visitor Data";
    // Define the headers
    const headers = [
      "SNo", "Date", "Txt Number", "Name", "From", 
      "Contact No", "Visitor Type", "Purpose", "Department", 
      "Sub Department", "Vehicle Number", "In Time", "Out Time", 
      "Total Time", "Approved By"
    ];

    // Set up the columns with specific widths
    sheet.columns = [
      
      { header: 'Date', key: 'date', width: 15 },
      { header: 'Time', key: 'time', width: 20 },
      // { header: 'Image', key: 'image', width: 20,}, 
      { header: 'Name', key: 'name', width: 20 },
      { header: 'From', key: 'company', width: 20 },
      { header: 'Contact No', key: 'contactNo', width: 20 },
      { header: 'Visitor Type', key: 'visitorType', width: 20 },
      { header: 'Purpose', key: 'purpose', width: 20 },
      { header: 'Department', key: 'department', width: 20 },
      { header: 'Sub Department', key: 'subDepartment', width: 20 },
      { header: 'Vehicle Number', key: 'vehicleNumber', width: 20 },
      { header: 'In Time', key: 'inTime', width: 20 }
    ];

    // Add the headers to the sheet
    headers.forEach((header, index) => {
      sheet.getCell(1, index + 1).value = header;
    });

    // Add data and images
    for (const [rowIndex, row] of newInspectionData.entries()) {
      const rowData = [
        row.date,
        row.time,
        // row.image, // Image URL
        row.inspectionSchedule,
        row.name,
        row.inspectDetails=="" ? "" : "Inspected",
        row.inspectDetails==""?"":row.inspectDetails?.inspectionLogs[0]?.employee?.employeeName,
        row.inspectDetails==""?"":row.inspectDetails?.inspectionLogs[0]?.checkLists.length,
        row.name,
        row.name,
        row.name,
        row.name,
      ];

      for (const [colIndex, cell] of rowData.entries()) {
        if(row.imageBase64 == null)
          {
            sheet.getCell(rowIndex + 2, colIndex + 1).value = cell;
          }
      //   else if (colIndex === 3 && cell.startsWith('http')) { // Image column
      //     // const base64Image = await fetchImageAsBase64(cell);
      //     const base64Image = row.imageBase64;
      //     console.log(base64Image);
      // console.log("reader");
      //     const imageId = workbook.addImage({
      //       base64: base64Image,
      //       extension: 'jpeg' // Change if your image is PNG
      //     });
      //     sheet.addImage(imageId, {
      //       tl: { col: colIndex, row: rowIndex + 1 }, // Top-left corner
      //       ext: { width: 60, height: 60 } // Resize image
      //     });
      //   } 
        else {
          sheet.getCell(rowIndex + 2, colIndex + 1).value = cell;
        }
      }
    }

    // Set row height (if needed)
    for (let rowIndex = 1; rowIndex <= sheet.rowCount; rowIndex++) {
      sheet.getRow(rowIndex).height = 60; // Set height in points
    }

    // Generate Excel file and save
    const excelBuffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([excelBuffer]), `${filename}.xlsx`);
    setSubmittingLoader(false);
  };

  // lazy loading 
  const handleScroll = (e) => {
    
    const bottomReached = (e.target.scrollHeight - e.target.scrollTop <= (parseInt(e.target.clientHeight) + 1)) &&  (e.target.scrollHeight - e.target.scrollTop >= (e.target.clientHeight));
    if(!loading)
      {
        console.log("->>>>> this ->>>>>>" + bottomReached);
        console.log("->>>>> this ->>>>>>" + (e.target.clientHeight + 1) );
        console.log("->>>>> this ->>>>>>" + e.target.scrollHeight );
        console.log("->>>>> this ->>>>>>" + e.target.scrollTop );
        console.log("->>>>> match ->>>>>>" + (e.target.scrollHeight-e.target.scrollTop) );
        if (bottomReached && !isFetching && newInspectionData.length < totalItems) {
          
          setIsFetching(true); // Prevent multiple fetches at the same time
          setPage(prevPage => prevPage + 1); // Load next page
        }
      }
  };

  useEffect(() => {
    if (isFetching) {
      dispatch(inspectionSummaryListGetAll(`&inspectionSchedule=${schedule}&page=${page}&limit=50`));
      setIsFetching(false);
    }
  }, [isFetching,page])
  useEffect(() => {
    if(startAppending)
        {
            addInspectionDataArray(inspectionList);
            console.log("Mark here");
        }
    setStartAppending(true);
  }, [inspectionList])
  useEffect(() => {
    setNewInspectionData([]);
  }, [searching])
  // Function to add new data
const addInspectionDataArray = (newDataArray) => {
  setNewInspectionData((prevData) => [...prevData,...newDataArray]);
};

const { isOpen, onOpen, onClose } = useDisclosure();

const printRef = useRef();
      const now = new Date();
      const formattedDateTime = now.toLocaleString();
      const handleDownloadPdf = async () => {
        setSubmittingLoader(true);
        const element = printRef.current;
        // Wait for all images to load
      const images = element.querySelectorAll('img');
      const promises = Array.from(images).map(img => {
        if (!img.complete) {
          return new Promise((resolve) => {
            img.onload = resolve;
            img.onerror = resolve;
          });
        }
      });

      await Promise.all(promises);
        const canvas = await html2canvas(element);
        const data = canvas.toDataURL('image/png');

        const pdf = new jsPDF({
          orientation: 'portrait',
          unit: 'pt',
          format: 'a4',
        });

        const imgProps = pdf.getImageProperties(data);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.text(`${authDetail.plant[0]?.plantName} - Inspection ${schedule} - ${formattedDateTime}`, 20, pdfHeight + 20);
        pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('Inspection.pdf');
        setSubmittingLoader(false);
      };

  return (
  <>
  {/* <PastAdvanceSearch></PastAdvanceSearch> */}
  <Flex direction="column" pt={{ base: "120px", md: "5px" }}>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
          <Flex>
            <CardHeader p="6px 0px 22px 0px">
              <Text fontSize="xl" color={textColor} fontWeight="bold">
                {schedule == ""?"ALL":schedule}
              </Text>
              <Text fontSize="xl" color={textColor} fontWeight="bold">
              {`${searchStartDate} to ${searchEndDate}`} <span><Tooltip hasArrow label="By default, today's data will be shown." aria-label='A tooltip' ><InfoOutlineIcon/></Tooltip></span>
              </Text>
            </CardHeader>
            <Spacer />

            <Flex justify="flex-end" style={{ padding: '10px', marginTop: '-5px', marginRight: '12px' }}>
              {/* <DownloadTableExcel
                filename="Past Visitor Data"
                sheet="users"
                currentTableRef={tableRef.current}
              > */}
                <Button 
                isLoading={submittingLoader}
                loadingText='Downloading'
                onClick={onOpen}
                // onClick={exportToExcel}
                colorScheme="blue" _hover={{ backgroundColor: 'black', color: 'white' }} >
                  Download
                </Button>
              {/* </DownloadTableExcel> */}
            </Flex>
            {/* Modal Component */}
            {/* Export */}
          <Export
            reportName = {schedule == ""?"ALL Inspection":`${schedule} Inspection`}
            isOpen={isOpen}
            onClose={onClose}
            onExport={exportToExcel}
            />
            <CardHeader p="6px 0px 22px 0px">
              <Text fontSize="xl" color={textColor} fontWeight="bold">
                <InputGroup borderRadius="8px" w="200px">
                  <InputLeftElement
                    children={
                      <IconButton
                        bg="inherit"
                        borderRadius="inherit"
                        _hover="none"
                        _active={{
                          bg: "inherit",
                          transform: "none",
                          borderColor: "transparent",
                        }}
                        _focus={{
                          boxShadow: "none",
                        }}
                        icon={
                          <SearchIcon
                            color={searchIconColor}
                            w="15px"
                            h="15px"
                          />
                        }
                      ></IconButton>
                    }
                  />
                  <Input
                    variant="search"
                    fontSize="xs"
                    bg={inputBg}
                    onKeyUp={(e) => {
                      setSearch(e.target.value);
                      handelSearch(e.target.value);
                      if (e.target.value.length < 3) {
                        setSearch("");
                        handelSearch("");
                      }
                    }}
                    placeholder="Type here..."
                  />
                </InputGroup>
              </Text>
            </CardHeader>
          </Flex>
          <CardBody pb={4}>
            <div className='vertical-scroll' // Set a fixed height for the scroll container
        onScroll={handleScroll}>
              {/* {loading ? <Stack>
                      <Skeleton height='20px' />
                      <Skeleton height='20px' />
                      <Skeleton height='20px' />
                      <Skeleton height='20px' />
                      <Skeleton height='20px' />
                      <Skeleton height='20px' />
                      <Skeleton height='20px' />
                    </Stack>: */}
              <Table variant="simple" color={textColor} 
            //   ref={tableRef} 
              ref={printRef}>
              <Thead className='stick-header'>
                <Tr my=".8rem" pl="0px" color="gray.400">
                <Th
                    pl="0px"
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("vechilenumber")}
                    cursor="pointer"
                  >
                    Date
                    {sortColumn === "vechilenumber" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                <Th
                    pl="0px"
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("createdAt")}
                    cursor="pointer"
                  >
                    Time
                    {sortColumn === "createdAt" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  
                  {/* <Th
                    pl="0px"
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("vechilenumber")}
                    cursor="pointer"
                  >
                    Image
                    {sortColumn === "vechilenumber" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th> */}
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("drivername")}
                    cursor="pointer"
                  >
                    Schedule
                    {sortColumn === "drivername" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("drivername")}
                    cursor="pointer"
                  >
                    Name/Item
                    {sortColumn === "drivername" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("intime")}
                    cursor="pointer"
                  >
                    Status
                    {sortColumn === "intime" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("intime")}
                    cursor="pointer"
                  >
                    Inspected By
                    {sortColumn === "intime" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("outtime")}
                    cursor="pointer"
                  >
                  Total Checklist
                    {sortColumn === "outtime" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("outtime")}
                    cursor="pointer"
                  >
                  Inspected
                    {sortColumn === "outtime" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("outtime")}
                    cursor="pointer"
                  >
                  Missed
                    {sortColumn === "outtime" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  

                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("outtime")}
                    cursor="pointer"
                  >
                  Remark
                    {sortColumn === "outtime" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("outtime")}
                    cursor="pointer"
                  >
                Image
                    {sortColumn === "outtime" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>

                </Tr>
              </Thead>
                <Tbody>
                  {newInspectionData ? (newInspectionData.map((row, index, arr) => {

                    return (
                      <AllTableRow
                      // serialnumber={totalItems - (((currentPage - 1) * limit) + index)}
                    //   serialnumber={totalItems - index}
                      date = {row.date}
                      time = {row.time}
                      schedule = {row.inspectionSchedule}
                      checkpostInName = {row.checkpostNameIn}
                      image = {row.inspectDetails==""?"":row.inspectDetails?.inspectionLogs[0]?.image}
                      name = {row.name}
                      status = {row.inspectDetails=="" ? "" : "Inspected"}
                      inspectedBy = {row.inspectDetails==""?"":row.inspectDetails?.inspectionLogs[0]?.employee?.employeeName}
                      totalChecklist ={row.inspectDetails==""?"":row.inspectDetails?.inspectionLogs[0]?.checkLists.length}
                      inspected = {row.inspectDetails==""?"":row.inspectDetails?.inspectionLogs[0]?.checkLists}
                      missed = {row.inspectDetails==""?"":row.inspectDetails?.inspectionLogs[0]?.checkLists}
                      remark = {row.inspectDetails==""?"":row.inspectDetails?.inspectionLogs[0]?.remark}
                      isLast={index === arr.length - 1 ? true : false}
                      id={row._id}
                      approvedBy={row.approvedBy}
                      />
                    );
                  })) : (<h1></h1>)}
                </Tbody>
              </Table>
              {loading ? <Center><Spinner mt="24px" size="lg" /></Center>:<></>}
              {/* } */}
            </div>
            {inspectionList.length === 0 ? (
              <></>
            ) : (
              <></>
              // <PastPagination
              //   totalPages={response.totalPages}
              //   currentPage={response.currentPage}
              //   perPage={response.perPage}
              //   sortBy={sortColumn}
              //   sortOrder={sortOrder}
              //   handlePageClick={(data) => {
              //     const selectedPage = data.selected + 1;
              //     dispatch(inspectionSummaryListGetAll(`&page=${selectedPage}&limit=50`));
              //   }}
              // />
            )}
          </CardBody>
        </Card>
    </Flex>
  </>
  );
};

export default AllTable;
