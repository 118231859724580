import React, { useEffect, useState, useRef } from 'react';
import { Flex,Button, InputGroup, Spacer, Table, Tbody, Text, Td, Th, Thead, Tr, useColorModeValue, InputLeftElement, IconButton, Input, Icon, Link,useDisclosure } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';

import { useToast } from '@chakra-ui/react';
import axios from 'axios';
import { server_url } from "constants/types";
import { useHistory } from 'react-router-dom';

import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import SosPagination from './SosPagination';
import { AddIcon ,ViewIcon} from '@chakra-ui/icons';
import { SOS_CLEAR_SNACKBAR , SOS_SEARCH } from 'constants/types';
import { sosGetAll } from '../../actions/sosAction';
import SosTableRow from './SosTableRow';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import SosSafeAdvanceSearch from './SosAdvanceSearch';
import { Skeleton, SkeletonCircle, SkeletonText,Stack } from '@chakra-ui/react'
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import Export from 'views/Export/Export';
const SosTable = () => {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const responseStatus = useSelector(state => state.sos.status);
  const responseMessage = useSelector(state => state.sos.text);
  const loading = useSelector(state => state.sos.loading);
  const sosList = useSelector(state => state.sos.sosList);
  const response = useSelector(state => state.sos.response);
  const limit = useSelector(state => state.sos.limit);
  const currentPage = useSelector(state => state.sos.page);
  const totalPages = useSelector(state => state.sos.totalPages);
  const totalItems = useSelector(state => state.sos.totalItems);
  const showSnackbar = useSelector(state => state.sos.showSnackbar);
  const dispatch = useDispatch();
  const toast = useToast();
  const searchIconColor = useColorModeValue("gray.700", "gray.200");
  const inputBg = useColorModeValue("#edf3f8", "navy.800");
  const [search, setSearch] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const history = useHistory();
  const tableRef = useRef(null);
  useEffect(() => {
    dispatch(sosGetAll());
    dispatch({
      type : SOS_SEARCH,
      payload : {
        searching : "",
      }
    })
  }, [dispatch]);

  console.log("sosList-----");
  console.log(sosList);

  useEffect(() => {
    if (showSnackbar) {
      if (responseStatus === 'OK' && !loading) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'success');
        }
      } else if (responseStatus === 'NOK' && !loading) {
        if (responseMessage !== "") {
          toastFunction(responseMessage, 'error');
        }
      }
    }
    dispatch({
      type: SOS_CLEAR_SNACKBAR,
      payload: {
        showSnackbar: false,
      }
    })
  }, [loading]);

  const toastFunction = (title, status) => {
    toast({
      title: title,
      status: status,
      duration: 3000,
      isClosable: true,
    })
  };

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("desc");
    }
    const sorting = "&sortOrder=" + sortOrder + "&column=" + column;
    dispatch({
      type : SOS_SEARCH,
      payload : {
        searching : "",
      }
    })
    dispatch(sosGetAll(sorting));
  };

  useEffect(() => {
    const searching = "&search=" + search;
    dispatch({
      type : SOS_SEARCH,
      payload : {
        searching : "",
      }
    })
    dispatch(sosGetAll(searching));
  }, [search]);
  useEffect(() => {
    axios
      .get(`${server_url}/admin/detail`, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        }
      }).then((responce) => {
        console.log("uadlfdafljadhfoadilf");
      })
      .catch((e) => {
        history.push('/auth/signin');

      });
  }, []);
  const [submittingLoader, setSubmittingLoader] = useState(false);
  const exportToExcel = async () => {
    setSubmittingLoader(true);
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet('Sheet 1');
    const filename = "Sos_data_"+Date.now();
    // Define the headers
    const headers = [
      "SNo","Date", "Time", "Type of Emergency", "Type", "Raised By", "Phone No",
      "Dept. & Sub-Dept.", "Acknowledge By Guard Name", "Issue Resolved Time"
    ];

    // Set up the columns with specific widths
    sheet.columns = [
      
      { header: 'SNo', key: 'sno', width: 15 },
      { header: 'Date', key: 'date', width: 15 },
      { header: 'Time', key: 'time', width: 20 },
      { header: 'Type of Emergency', key: 'typeOfEmergency', width: 20,}, // Adjust width as needed
      { header: 'Type', key: 'type', width: 20 },
      { header: 'Raised By Name', key: 'raisedBy', width: 20 },
      { header: 'Phone No.', key: 'phoneNo', width: 20 },
      { header: 'Dept. & Sub-Dept.', key: 'department', width: 20 },
      { header: 'Acknowledge By Guard Name', key: 'acknowledgeBy', width: 20 },
      { header: 'Issue Resolved Time', key: 'issueResolvedTime', width: 20 }
    ];

    // Add the headers to the sheet
    headers.forEach((header, index) => {
      sheet.getCell(1, index + 1).value = header;
    });

    // Add data and images
    for (const [rowIndex, row] of sosList.entries()) {
      const rowData = [
        totalItems - (((currentPage - 1) * limit) + rowIndex),
        row.date,
        row.createdDetails?.time,
        row.sosCategory?.name,
        row.createdDetails?.designation,
        row.createdDetails?.name,
        row.createdDetails?.contactNo,
        row.department?.name,
        row.acceptedDetails.name !== "" ? row.acceptedDetails.name : "Not Accepted",
        row.acceptedDetails.time !== "" ? row.acceptedDetails.time : "--",
      ];

      for (const [colIndex, cell] of rowData.entries()) {
        if(colIndex == 6)
        {
          sheet.getCell(rowIndex + 2, colIndex + 1).value = `${row.department?.name}\n${row.subdepartment?.name}`;
        }
        else if(colIndex == 7)
        {
          sheet.getCell(rowIndex + 2, colIndex + 1).value = `${row.acceptedDetails.name !== "" ? row.acceptedDetails.name : "Not Accepted"}\n${row.acceptedDetails?.time}`;
        }
        else
        {
          sheet.getCell(rowIndex + 2, colIndex + 1).value = cell;
        }
        
      }
    }

    // Set row height (if needed)
    for (let rowIndex = 1; rowIndex <= sheet.rowCount; rowIndex++) {
      sheet.getRow(rowIndex).height = 60; // Set height in points
    }

    // Generate Excel file and save
    const excelBuffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([excelBuffer]), `${filename}.xlsx`);
    setSubmittingLoader(false);
  };
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
    <SosSafeAdvanceSearch></SosSafeAdvanceSearch>
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <Flex>
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Sos
            </Text>
          </CardHeader>
          <Spacer />
          {/* <Flex justify="flex-end" style={{ padding: '10px', marginTop: '-5px', marginRight: '12px' }}>
            <Link href={`#/admin/sos-form`}>
              <IconButton
                colorScheme="blue"
                aria-label="Search database"
                icon={<AddIcon />}
              />
            </Link>
          </Flex> */}
          <Flex justify="flex-end" style={{ padding: '10px', marginTop: '-5px', marginRight: '12px' }}>
            {/* <DownloadTableExcel
              filename="Sos Table"
              sheet="users"
              currentTableRef={tableRef.current}
            > */}
              <Button 
              loading={submittingLoader}
              onClick={onOpen}
              colorScheme="blue" _hover={{ backgroundColor: 'black', color: 'white' }}>
                Download
              </Button>
            {/* </DownloadTableExcel> */}
          </Flex>
          {/* Export */}
          <Export
            reportName = "Transport Data"
            isOpen={isOpen}
            onClose={onClose}
            onExport={exportToExcel}
            />
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              <InputGroup borderRadius="8px" w="200px">
                <InputLeftElement
                  children={
                    <IconButton
                      bg="inherit"
                      borderRadius="inherit"
                      _hover="none"
                      _active={{
                        bg: "inherit",
                        transform: "none",
                        borderColor: "transparent",
                      }}
                      _focus={{
                        boxShadow: "none",
                      }}
                      icon={
                        <SearchIcon
                          color={searchIconColor}
                          w="15px"
                          h="15px"
                        />
                      }
                    ></IconButton>
                  }
                />
                <Input
                  variant="search"
                  fontSize="xs"
                  bg={inputBg}
                  onKeyUp={(e) => {
                    setSearch(e.target.value);
                    if (e.target.value.length < 3) {
                      setSearch("");
                    }
                  }}
                  placeholder="Type here..."
                />
              </InputGroup>
            </Text>
          </CardHeader>
        </Flex>
        <CardBody pb={4}>
          <div className='vertical-scroll' >
          
            {loading ? <Stack>
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                  </Stack>:<Table variant="simple" color={textColor} ref={tableRef}>
              <Thead className='stick-header'>
                <Tr my=".8rem" pl="0px" color="gray.400">
                <Th
                    pl="15px"
                    borderColor={borderColor}
                    color="gray.400"
                    cursor="pointer"
                  >
                   SNO
                  </Th>
                  <Th
                    pl="0px"
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("name")}
                    cursor="pointer"
                  >
                    Date 
                    {sortColumn === "name" && (
                      <Icon
                        as={createdAt === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    pl="0px"
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("name")}
                    cursor="pointer"
                  >
                   Raised Time
                    {sortColumn === "name" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    pl="0px"
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("name")}
                    cursor="pointer"
                  >
                    Type of Emegency 
                    {sortColumn === "name" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    pl="0px"
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("name")}
                    cursor="pointer"
                  >
                  Type
                    {sortColumn === "name" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  
                  <Th
                    pl="0px"
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("name")}
                    cursor="pointer"
                  >
                  Raised By Name
                    {sortColumn === "name" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    pl="0px"
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("name")}
                    cursor="pointer"
                  >
                  Mobile No.
                    {sortColumn === "name" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  <Th
                    pl="0px"
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("name")}
                    cursor="pointer"
                  >
                  Dept. & Sub-Dept.
                    {sortColumn === "name" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  
                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("photo")}
                    cursor="pointer"
                  >
                   Acknowledged <br/>by Guard name
                    {sortColumn === "photo" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>

                  <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("number")}
                    cursor="pointer"
                  >
                   Issue resloved time
                    {sortColumn === "number" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th>
                  {/* <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("number")}
                    cursor="pointer"
                  >
                    Accepted At Name
                    {sortColumn === "number" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th> */}
                  {/* <Th
                    borderColor={borderColor}
                    color="gray.400"
                    onClick={() => handleSort("photo")}
                    cursor="pointer"
                  >
                    Accepted By photo
                    {sortColumn === "photo" && (
                      <Icon
                        as={sortOrder === "asc" ? FaChevronUp : FaChevronDown}
                        boxSize={4}
                        ml={1}
                      />
                    )}
                  </Th> */}



                  <Th borderColor={borderColor}></Th>
                </Tr>
              </Thead>
              <Tbody>

                {sosList ? (sosList.map((row, index, arr) => {
                  return (

                    <SosTableRow
                     serialnumber={totalItems - (((currentPage - 1) * limit) + index)}
                     date={row.date}
                      createdName={row.createdDetails ? row.createdDetails.name : ""}
                      createdNumber={row.createdDetails ? row.createdDetails.contactNo : ""}
                      createdDesignation={row.createdDetails ? row.createdDetails.designation : ""}
                      createdTime={row.createdDetails ? row.createdDetails.time : ""}
                      acceptedName={row.acceptedDetails.name !== "" ? row.acceptedDetails.name : "Not Accepted"}
                      acceptedTime={row.acceptedDetails.time}
                      // resolvedTime={row.resolvedDetails.time}
                      sosCategoryName={row.sosCategory ? row.sosCategory.name : ""}
                      isLast={index === arr.length - 1 ? true : false}
                      id={row._id}
                      departmentName={row.department?.name}
                      subdepartmentName={row.subdepartment?.name}
                    />
                  );
                })) : (<h1></h1>)
                }

              </Tbody>
            </Table>}
          </div>
          {sosList.length === 0 ? (
            <></>
          ) : (
            <SosPagination
              limit = {response.limit}
              totalPages={response.totalPages}
              currentPage={response.currentPage}
              perPage={response.perPage}
              sortBy={sortColumn}
              sortOrder={sortOrder}
              handlePageClick={(data) => {
                const selectedPage = data.selected + 1;
                dispatch(sosGetAll(`&page=${selectedPage}`));
              }}
            />
          )}
        </CardBody>
      </Card>
    </Flex></>
  );
};

export default SosTable;
